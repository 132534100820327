import { CorporateContentModules } from '@klokgroep/shared-components/src/CorporateContentModules';
import {
  CorporateHomepageResponse,
  getCorporateHomepage,
  createCorporateHomepageQuery,
} from '@sanity/queries/corporateHomepage';
import { getCorporateSiteIdPaths } from 'utils/hostnames';
import {
  getPropertiesWithPreviewAndSitewideData,
  PropertiesWithPreviewNavigationAndSocialsAndMetaData,
} from '@klokgroep/shared-components/utils/getPropertiesWithPreviewNavigationAndSocials';
import { getRevalidateInterval } from '@klokgroep/shared-components/utils/getRevalidateInterval';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { createHref, imageUrlFor, SiteIdType } from '@klokgroep/sanity';
import { PageHero, moduleAddTitle } from '@klokgroep/shared-components/src/PageHero';
import { ButtonLink } from '@klokgroep/shared-components/src/Button';
import { useSiteInfo } from '@klokgroep/shared-components/src/SiteInfoProvider';
import { RichText } from '@klokgroep/shared-components/src/RichText';
import { PortableTextWithLinks } from '@klokgroep/shared-components/src/PortableTextWithLinks';

type Properties = PropertiesWithPreviewNavigationAndSocialsAndMetaData<CorporateHomepageResponse>;

const Homepage: NextPage<Properties> = ({ homepageHero, corporateContentModules = [] }) => {
  const { locale, siteId } = useSiteInfo();
  return (
    <section>
      <PageHero
        mediaItems={homepageHero?.mediaItems}
        highlightedContentBlock={homepageHero?.highlightedContentBlock}
        hasPadding
        heroContent={homepageHero?.title ? moduleAddTitle(homepageHero?.title) : undefined}>
        {homepageHero?.extraContent?.richText ||
        (homepageHero?.extraContent?.button && homepageHero?.extraContent?.button.label) ? (
          <RichText large>
            {homepageHero?.extraContent?.richText ? (
              <PortableTextWithLinks content={homepageHero?.extraContent?.richText} />
            ) : undefined}
            {homepageHero?.extraContent?.button && homepageHero?.extraContent?.button.label ? (
              <ButtonLink
                theme={siteId === 'vandeklok' ? 'orange' : undefined}
                target={homepageHero?.extraContent?.button?.targetBlank ? 'blank' : undefined}
                href={createHref({ ...homepageHero?.extraContent?.button, locale })}>
                {homepageHero?.extraContent?.button?.label}
              </ButtonLink>
            ) : undefined}
          </RichText>
        ) : undefined}
      </PageHero>
      <CorporateContentModules modules={corporateContentModules} />
    </section>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  const paths = getCorporateSiteIdPaths();
  return { paths, fallback: 'blocking' };
};

export const getStaticProps: GetStaticProps<Properties, { siteId: SiteIdType }, { token?: string }> = async ({
  preview = false,
  params,
}) => {
  if (!params?.siteId) {
    return { notFound: true, revalidate: getRevalidateInterval() };
  }

  const homepage = await getCorporateHomepage(false, params.siteId);
  const dataForPreview = {
    query: createCorporateHomepageQuery(params.siteId),
    params: { siteId: params.siteId },
  };

  const properties = await getPropertiesWithPreviewAndSitewideData(
    preview,
    dataForPreview,
    homepage,
    {
      description: homepage?.seo?.pageSeo?.description || homepage?.seo?.pageSeo?.description,
      image:
        (homepage?.homepageHero?.overviewImage
          ? imageUrlFor(homepage?.homepageHero?.overviewImage).url()
          : undefined) ||
        (homepage?.homepageHero?.mediaItems
          ? homepage?.homepageHero?.mediaItems[0]?._type == 'image'
            ? imageUrlFor(homepage?.homepageHero?.mediaItems[0]).url()
            : undefined
          : undefined),
      title: homepage?.seo?.pageSeo?.title || homepage?.homepageHero?.title,
      noIndex: homepage?.seo?.pageSeo?.noIndex,
      schema: {
        socials: homepage?.seo?.socials,
        organisation: {
          name: homepage?.seo?.sitewideSeo?.organisationName,
          legalName: homepage?.seo?.sitewideSeo?.organisationLegalName,
          description: homepage?.seo?.sitewideSeo?.organisationDescription,
          founderName: homepage?.seo?.sitewideSeo?.organisationFounder,
          foundingDate: homepage?.seo?.sitewideSeo?.organisationFoundingDate,
          numberOfEmployees: homepage?.seo?.sitewideSeo?.organisationNumberOfEmployees,
          logo: homepage?.seo?.sitewideSeo?.organisationLogo,
          slogan: homepage?.seo?.sitewideSeo?.organisationSlogan,
        },
      },
    },
    params.siteId
  );

  return {
    props: properties,
    revalidate: getRevalidateInterval(),
  };
};

export default Homepage;
